<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="6"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="5">
              <v-img
                :src="logo"
                contain
              />
            </v-col>
          </v-row>
          <base-material-card
            class="text-center"
            color="primary"
          >
            <template v-slot:heading>
              <v-row>
                <v-col>
                  <div class="display-3 font-weight-light">
                    {{ $t('app.login.title') }}
                  </div>

                  <div class="subtitle-1 font-weight-light">
                    {{ $t('app.login.subtitle') }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <v-form
              @keyup.native.enter="actionLogin"
            >
              <v-text-field
                v-model="credentials.email"
                :label="$t('app.login.username')"
                class="purple-input"
              />
              <v-text-field
                v-model="credentials.password"
                :label="$t('app.login.password')"
                class="purple-input"
                type="password"
              />
              <v-btn
                color="primary"
                block
                @click.prevent="actionLogin"
              >
                {{ $t('app.login.title') }}
              </v-btn>
            </v-form>

            <v-row>
              <v-col
                cols="6"
                class="text-center clickable"
                @click="setLanguage('en')"
              >
                <flag iso="us" />
                {{ $t('app.languages.english') }}
              </v-col>
              <v-col
                cols="6"
                class="text-center clickable"
                @click="setLanguage('es')"
              >
                <flag iso="mx" />
                {{ $t('app.languages.spanish') }}
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import {mapMutations, mapState} from 'vuex'

  export default {
    name: 'Login',
    data: () => ({
      loader: false,
      credentials: {
        email: '',
        password: '',
      },
    }),
    computed: {
      ...mapState('app', ['user', 'loggedIn', 'lang']),
    },
    methods: {
      ...mapMutations({
        setLogin: 'SET_LOGGED_IN',
        setLang: 'SET_LANG',
        setUser: 'SET_USER',
        setToken: 'SET_TOKEN',
        setRoles: 'SET_ROLES',
        setDivisaBase: 'SET_DIVISA_BASE',
        setFormasPago: 'SET_FORMAS_PAGO',
      }),
      actionLogin () {
        if (this.credentials.email.length > 0 && this.credentials.password.length > 0) {
          const credentials = {
            email: this.credentials.email,
            password: this.credentials.password
          }

          this.toggleLoader()

          this.$http.post(route('v1/auth/login'), credentials)
            .then(async response => {
              if (response.body.code === 200) {
                this.credentials.email = ''
                this.credentials.password = ''

                const token = response.body.data[0].token

                this.setUser(response.body.data[0])
                this.setToken(token)

                this.setDivisaBase(await this.getDivisaBase(token))
                this.setFormasPago(await this.getFormasPago(token))

                const roles = await this.getRoles(token)
                this.setRoles(roles)
                this.setLogin(true)
                this.$router.push({
                  name: 'AdminDashboard',
                })
              } else {
                this.processError(response)
              }
            }, error => {
              this.processError(error)
            }).finally(() => {
              this.toggleLoader()
            })
        } else {
          this.errorMessage(this.$t('app.errors.invalid_credentials'))
        }
      },
      setLanguage (lang) {
        this.setLang(lang)
        this.$root.$i18n.locale = lang
      },
    },
  }
</script>

<style scoped>

</style>
